
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import apiSettings from "@/core/services/Settings";
import apiEntity from "@/core/services/Entities";
import apiResult from "@/core/services/Result";
import campaignApi from "@/core/services/Campaign";
import resultApi from "@/core/services/Result";
import ApiService from "@/core/services/ApiService";
import { GET_ALL_REPORTS_URL } from "@/core/services/Result";

export default defineComponent({
    name: "results",
    components: {},
    data() {
        return {
            defaultExpandedKeys: [] as number[],
            groupReport: "",
            groupReportLink: "",
            entities: [],
            defaultProps: {
                children: "companies",
                label: "name",
            },
            selectedYear: 0,
            selectableYears: [] as any,
            loading: false,
            loadingZip: false,
            progressBarWidth: 0,
            campaigns: [] as any,
        };
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.results.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.results.title"), []);
        },
    },
    methods: {
        reloadData() {
            this.fetchEntities();
            this.fetchGroupReport();
        },
        handleNodeClick(data) {
            if (this.defaultExpandedKeys.indexOf(data.id) !== -1) {
                this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.id), 1);
            } else {
                if (data && data.id) {
                    // Check if the node already has children loaded
                    this.fetchEntities(data.id);
                    this.defaultExpandedKeys.push(data.id);
                }
            }
        },
        updateTree(company_id, children) {
            const findAndUpdateNode = (nodes) => {
                return nodes.map((node) => {
                    if (node.id === company_id) {
                        // Return a new object with updated companies
                        return { ...node, companies: children };
                    } else if (node.companies && node.companies.length) {
                        // Recursively update child nodes
                        return { ...node, companies: findAndUpdateNode(node.companies) };
                    } else {
                        // Return the node as is if no updates are needed
                        return node;
                    }
                });
            };

            // Update the entities with the new structure
            this.entities = findAndUpdateNode(this.entities);
        },
        fetchEntities(company_id = null) {
            const params = { year: new Date().getFullYear(), company_id: null };
            if (company_id) {
                params.company_id = company_id;
            }
            apiEntity.getHierarchicalEntities(params).then((response) => {
                if (company_id) {
                    this.updateTree(company_id, response.data["hierarchical_companies"]);
                } else {
                    this.entities = response.data["hierarchical_companies"];
                }
            });
        },
        fetchCampaigns() {
            campaignApi.getCampaigns().then((response) => {
                this.campaigns = response.data["campaigns"];
                this.selectedYear = this.campaigns[0].id;
                this.fetchEntities();
                this.fetchGroupReport();
            });
        },
        getAllReports() {
            this.updateProgressBar(0);
            this.loadingZip = true;
            this.axios
                .post(ApiService.returnUrl() + GET_ALL_REPORTS_URL, { campaign: this.selectedYear }, { responseType: "blob" })
                .then((response) => {
                    const blob = new Blob([response.data], { type: "application/zip" });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "reports.zip";
                    link.click();
                    window.URL.revokeObjectURL(url);
                    setTimeout(() => {
                        this.updateProgressBar(100);
                        setTimeout(() => {
                            this.loadingZip = false;
                        }, 1500);
                    }, 500);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        downloadGroupReport() {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = this.groupReportLink;
            a.download = "rapport-groupe.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        downloadReport(fileres) {
            resultApi.getReport(fileres.id).then((response) => {
                let report = response.data.report;
                let type = report.extension;
                let original_type = type;
                if (type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    type = "xlsx";
                }
                let linkSource = report.file;
                const downloadLink = document.createElement("a");
                const fileName = report.name + "_" + report.id + "." + type;
                let dataPart = "";
                if (type === "pdf") {
                    dataPart = "data:application/pdf;base64,";
                } else if (type === "xlsx") {
                    dataPart = "data:vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
                } else if (type === "xls") {
                    dataPart = "data:application/vnd.ms-excel;base64,";
                } else if (type === "ppt") {
                    dataPart = "data:application/vnd.ms-powerpoint;base64,";
                } else if (type === "pptx") {
                    dataPart = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,";
                } else if (type === "docx") {
                    dataPart = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
                } else if (type === "doc") {
                    dataPart = "data:application/msword;base64,";
                } else if (type === "txt") {
                    dataPart = "data:text/plain;base64,";
                } else if (type === "csv") {
                    dataPart = "data:text/csv;base64,";
                } else if (type === "jpeg" || type === "jpg") {
                    dataPart = "data:image/jpeg;base64,";
                }
                if (linkSource.startsWith("b'")) {
                    linkSource = linkSource.substring(2);
                }
                if (linkSource.endsWith("'")) {
                    linkSource = linkSource.slice(0, -1);
                }
                let total = dataPart + linkSource;
                downloadLink.href = total;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
        },
        fetchGroupReport() {
            apiSettings.getGroupReport({ year: this.selectedYear }).then((response) => {
                if ("result" in response.data && "file" in response.data["result"]) {
                    this.groupReport = response.data["result"]["file"];
                    if (this.groupReport && this.groupReport !== "") {
                        const base64result = this.groupReport.split(",")[1];
                        const blob = this.b64toBlob(base64result, this.groupReport.split(",")[0]);
                        this.groupReportLink = URL.createObjectURL(blob);
                    } else {
                        this.groupReportLink = "";
                    }
                } else {
                    this.groupReportLink = "";
                }
            });
        },
        b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [] as Array<Uint8Array>;

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
        startProgressBarTimer() {
            setInterval(() => {
                if (this.progressBarWidth < 90) {
                    this.progressBarWidth += 10;
                }
            }, 1000);
        },
        updateProgressBar(progress) {
            this.progressBarWidth = progress;
        },
    },
    mounted() {
        this.startProgressBarTimer();
        setTimeout(() => {
            this.updateProgressBar(90);
        }, 9000);
    },
    created() {
        this.fetchCampaigns();
    },
});
